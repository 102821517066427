import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import InputField from '../../../components/shared/formFields/InputField';
import SelectField from '../../../components/shared/formFields/SelectField';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
  Stack,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  IconButton,
  InputAdornment,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../../../context/AuthContext';
import { Delete } from '@mui/icons-material';

interface User {
  email?: string;
  given_name?: string;
  family_name?: string;
}

function CartDisplay() {
  const {
    cartItemList,
    increaseQuantity,
    decreaseQuantity,
    subTotal,
    total,
    calculateVatAmount,
    vatAmount,
    addAdditionalCartItemDetails,
    formSteps,
    updateQuantity,
    loadingItemId,
    loadingDeleteItemId,
    removeFromCart,
    billingAddress,
    cartItemData,
    updateOrderNotes,
  } = useShoppingCart();

  const [open, setOpen] = useState<boolean>(false);

  const [cartItem, setCartItem] = useState<any>();
  const [openFormFields, setOpenFormFields] = useState<boolean>(false);
  const [openOrderNotes, setOpenOrderNotes] = useState<boolean>(false);

  const handleOrderNotesOpen = (item: any) => {
    setCartItem(item);
    setOpenOrderNotes(true);
    reset({ orderNotes: item.orderNotes || '' });
  };

  const handleOrderNotesClose = () => {
    setOpenOrderNotes(false);
  };

  const handleClickOpen = (item: any) => {
    setCartItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
    setOpenFormFields(false);
  };

  const { user } = useAuth() as { user: User | undefined };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<any>();

  const formSubmit = handleSubmit((data, e) => {
    e?.preventDefault();
    const shippingAddressFields = {
      name: data.name,
      email: data.email,
      surname: data.surname,
      address: data.address,
      apartment: data.apartment,
      suburb: data.suburb,
      city: data.city,
      country: data.country,
      province: data.province,
      postalCode: data.postalCode,
      contact_info: data.contact_info,
      order_notes: data.order_notes,
    };

    try {
      addAdditionalCartItemDetails(
        cartItem,
        cartItem?.altShippingAddress?.address === 'Pick up selected'
          ? {
              name: user?.given_name,
              last: user?.family_name,
              email: user?.email,
              address: 'Pick up ',
            }
          : shippingAddressFields
      );
    } catch (error) {
      console.error('Failed to update cart item:', error);
    }

    setOpenFormFields(false);
    setOpen(false);

    reset();
  });

  const handleOrderNotesSubmit = async () => {
    try {
      const orderNotes = getValues('orderNotes') || '';
      await updateOrderNotes(cartItem?.id, orderNotes);
      handleOrderNotesClose();
    } catch (error) {
      console.error('Failed to update order notes:', error);
    }
  };

  useEffect(() => {
    calculateVatAmount();
  }, [calculateVatAmount]);

  const [errorInput, setErrorInput] = useState(false);
  const handleQuantityChange = (
    elem: any,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const value = e.target.value;
    const numberValue = Number(value);
    const isValidInput =
      /^\d+(\.\d{0,1})?$/.test(value) && numberValue <= elem.stock;
    if (isValidInput) {
      updateQuantity(elem.id, numberValue);
      setErrorInput(false);
    } else {
      setErrorInput(true);
    }
  };
  const preventDecimal = (e: React.KeyboardEvent) => {
    const value = (e.target as HTMLInputElement).value;
    const parts = value.split('.');
    if (e.key === '.' && parts.length > 1) {
      e.preventDefault();
    }
    if (parts.length === 2 && parts[1].length >= 1 && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };
  return (
    <Container sx={{ mt: 5 }} maxWidth="sm">
      <>
        {cartItemList.length > 0 ? (
          <>
            {cartItemList.length > 0 && (
              <>
                <Typography
                  sx={{
                    mt: 2,
                    textAlign: 'left',
                    fontWeight: '600',
                    fontSize: '22px',
                  }}
                >
                  My Cart
                </Typography>
                {loadingDeleteItemId ? (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {cartItemList.map((elem: any, index: number) => (
                      <Box key={index}>
                        <Grid container mt={2}>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              bgcolor: '#FFF',
                              borderTopLeftRadius: '4px',
                              borderTopRightRadius: '4px',
                              p: '16px',
                            }}
                          >
                            <Box
                              sx={{
                                flex: 1,
                                height: '92px',
                                width: '92px',
                              }}
                            >
                              <img
                                src={elem.image}
                                alt="cart item"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                ml: 2,
                                flex: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    letterSpacing: '-0.2px',
                                    lineHeight: '24px',
                                  }}
                                >
                                  {elem.name}
                                </Typography>
                                <IconButton
                                  onClick={() => removeFromCart(elem)}
                                >
                                  <Delete
                                    htmlColor="#000"
                                    sx={{
                                      height: '18px',
                                      width: '18px',
                                    }}
                                  />
                                </IconButton>
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: '400',
                                  letterSpacing: '-0.2px',
                                  lineHeight: '21px',
                                  color: '#737373',
                                }}
                              >{`Description: ${elem.description}`}</Typography>
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                {elem.group === 'Fabric' ? (
                                  <Box>
                                    <TextField
                                      defaultValue={elem.quantity}
                                      type="text"
                                      onChange={(e) =>
                                        handleQuantityChange(elem, e)
                                      }
                                      onKeyDown={preventDecimal}
                                      variant="outlined"
                                      size="small"
                                      error={errorInput}
                                      helperText={
                                        errorInput ? (
                                          <Typography>
                                            There are only {elem.stock} in
                                            stock.
                                          </Typography>
                                        ) : null
                                      }
                                      InputProps={{
                                        sx: {
                                          width: '60%',
                                          p: '0 0 0 1',
                                          textAlign: 'center',
                                          background:
                                            'linear-gradient(to right, #EAECF0 50%, #FFF 50%)',
                                          fontSize: '14px',
                                          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                            {
                                              '-webkit-appearance': 'none',
                                              margin: 0,
                                            },
                                          '& input[type="number"]': {
                                            '-moz-appearance': 'textfield',
                                          },
                                        },
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <Typography
                                              fontSize={'14px'}
                                              color={'#000'}
                                            >
                                              Meters
                                            </Typography>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      border: '#EAECF0 1px solid',
                                      bgcolor: '#EAECF0',
                                      ':hover': {
                                        bgcolor: '#EAECF0',
                                      },
                                    }}
                                  >
                                    <Button
                                      sx={{
                                        height: '32px',
                                        width: '32px',

                                        padding: '0',
                                        bgcolor: '#FFF',
                                        ':hover': {
                                          bgcolor: '#FFF',
                                        },
                                      }}
                                      disabled={
                                        elem.quantity === 1 ? true : false
                                      }
                                      onClick={() => {
                                        decreaseQuantity(elem);
                                      }}
                                    >
                                      <RemoveIcon
                                        sx={{
                                          cursor: 'pointer',

                                          fontSize: '14px',
                                        }}
                                      />
                                    </Button>

                                    <Typography px={3} fontSize="14px">
                                      {elem.quantity}
                                    </Typography>
                                    <Button
                                      sx={{
                                        height: '32px',
                                        width: '32px',

                                        padding: '0',
                                        bgcolor: '#FFF',
                                        ':hover': {
                                          bgcolor: '#FFF',
                                        },
                                      }}
                                      disabled={elem.quantity === elem.stock}
                                      onClick={() => {
                                        increaseQuantity(elem);
                                      }}
                                    >
                                      <AddIcon
                                        sx={{
                                          fontSize: '14px',
                                        }}
                                      />
                                    </Button>
                                  </Box>
                                )}
                                {loadingItemId === elem.id ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {`R${elem.price.toFixed(2)}`}
                                  </Typography>
                                )}
                              </Grid>
                              {elem?.discount_type !== 'normal' && (
                                <>
                                  <Typography fontWeight={'500'}>
                                    Discounted price applied*
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                        <Divider
                          sx={{
                            mx: '16px',
                          }}
                        />
                        {/* Order Notes & Alternative Delivery Address */}
                        {formSteps === 2 && (
                          <>
                            {' '}
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="flex-end"
                              sx={{
                                background: '#FFF',
                                p: '10px 16px 16px 16px',
                                borderBottomLeftRadius: '4px',
                                borderBottomRightRadius: '4px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                  width: '100%',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '12px',
                                    justifyContent: 'space-between',
                                    pb: 0.5,
                                    borderBottom: '1px solid #F5F5F5',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '12px',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        background: '#EAECF0',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '92px',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: '10px',
                                          fontWeight: '600',
                                          color: '#000',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Ship to{' '}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#737373',
                                      }}
                                    >
                                      {elem?.altShippingAddress?.address == null
                                        ? billingAddress?.address
                                        : elem?.altShippingAddress?.address}
                                    </Typography>
                                  </Box>
                                  <Box
                                    onClick={() => {
                                      handleClickOpen(elem);
                                    }}
                                    sx={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        cursor: 'pointer',
                                        color: '#000',
                                      }}
                                    >
                                      Edit
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '12px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '12px',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        background: '#EAECF0',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '92px',
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: '10px',
                                          fontWeight: '600',
                                          color: '#000',
                                          textTransform: 'uppercase',
                                        }}
                                      >
                                        Order Notes
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#737373',
                                      }}
                                    >
                                      {!elem?.orderNotes
                                        ? 'None'
                                        : elem?.orderNotes}
                                    </Typography>
                                  </Box>

                                  <Box
                                    onClick={() => {
                                      handleOrderNotesOpen(elem);
                                    }}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'start',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        cursor: 'pointer',
                                        color: '#000',
                                      }}
                                    >
                                      Edit
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </>
                        )}
                      </Box>
                    ))}
                  </>
                )}
                <Divider sx={{ mt: 3 }} />
                <Stack mt={3} mb={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#545454',
                      }}
                    >
                      Subtotal
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '400',
                        color: '#545454',
                      }}
                    >
                      R{subTotal.toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#545454',
                        fontWeight: '400',
                      }}
                    >
                      VAT
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '18px',
                        fontWeight: '400',
                        color: '#545454',
                      }}
                    >
                      R{vatAmount.toFixed(2)}
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
                <Grid container justifyContent="space-between" mt={3}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#545454',
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontWeight: '600',
                      color: '#333',
                    }}
                  >
                    R{total.toFixed(2)}
                  </Typography>
                </Grid>

                {/* Item Modal */}

                <Dialog open={openOrderNotes} onClose={handleOrderNotesClose}>
                  <DialogTitle>
                    <Typography
                      sx={{
                        fontWeight: '600',
                      }}
                    >
                      Edit Order Notes.
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="orderNotes"
                      {...register('orderNotes')}
                      label="Order Notes"
                      type="text"
                      required={true}
                      fullWidth
                      variant="standard"
                      defaultValue={cartItem?.orderNotes || ''}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleOrderNotesClose}>Cancel</Button>
                    <Button
                      onClick={() => {
                        handleOrderNotesSubmit();
                      }}
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>
                    <Typography
                      sx={{
                        fontWeight: '600',
                      }}
                    >
                      Edit Delivery Details.
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please enter an alternative delivery address
                    </DialogContentText>
                    <Typography fontWeight="bold" mt={1}>
                      Details:
                    </Typography>

                    <Box>
                      <Typography>{`Product ID: ${cartItem?.id}`}</Typography>
                      {!cartItem?.altShippingAddress?.email ? (
                        ''
                      ) : (
                        <Typography>{`Contact Person: ${cartItem?.altShippingAddress?.contact_info}`}</Typography>
                      )}
                      <Typography>{`Email: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.email
                          : cartItem?.altShippingAddress?.email
                      }`}</Typography>

                      <Typography>{`Product Name: ${cartItem?.name}`}</Typography>
                      <Typography>{`Name: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.name
                          : cartItem?.altShippingAddress?.name
                      }`}</Typography>
                      <Typography>{`Surname: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.name
                          : cartItem?.altShippingAddress?.surname
                      }`}</Typography>
                      <Typography>{`Address: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.address
                          : cartItem?.altShippingAddress?.address
                      }`}</Typography>
                      <Typography>{`Suburb: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.suburb
                          : cartItem?.altShippingAddress?.suburb
                      }`}</Typography>
                      <Typography>{`City: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.city
                          : cartItem?.altShippingAddress?.city
                      }`}</Typography>
                      <Typography>{`Country: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.country
                          : cartItem?.altShippingAddress?.country
                      }`}</Typography>
                      <Typography>{`Province: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.province
                          : cartItem?.altShippingAddress?.province
                      }`}</Typography>
                      <Typography>{`Postal Code: ${
                        !cartItem?.altShippingAddress
                          ? billingAddress?.postalCode
                          : cartItem?.altShippingAddress?.postalCode
                      }`}</Typography>
                    </Box>

                    {!openFormFields && (
                      <Grid mt={3} container justifyContent="center">
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={() => {
                            setOpenFormFields(true);
                          }}
                        >
                          Edit details
                        </Button>
                      </Grid>
                    )}

                    {openFormFields && (
                      <form>
                        {cartItem?.altShippingAddress?.address ===
                        'Pick up selected' ? (
                          <>
                            <Typography fontWeight="bold">Shipping:</Typography>
                            <Typography>Pick up selected</Typography>
                          </>
                        ) : (
                          <Stack mt={2}>
                            <Typography fontWeight="bold">
                              Edit Shipping address
                            </Typography>
                            <InputField
                              marginTop={2}
                              type="text"
                              id="order_notes"
                              name="order_notes"
                              defaultValue={
                                !cartItem?.altShippingAddress?.order_notes
                                  ? cartItemData?.order_notes
                                  : cartItem?.altShippingAddress?.order_notes
                              }
                              placeholder="Enter delivery notes"
                              multiline
                              register={register}
                              errors={errors}
                              errorMsg="Please enter your delivery notes"
                            />
                            <InputField
                              marginTop={2}
                              type="number"
                              id="contact_info"
                              name="contact_info"
                              defaultValue={
                                !cartItem?.altShippingAddress?.contact_info
                                  ? cartItemData?.contact_number
                                  : cartItem?.altShippingAddress?.contact_info
                              }
                              placeholder="Enter recipient contact number"
                              register={register}
                              errors={errors}
                              errorMsg="Please enter recipient contact number"
                            />

                            <InputField
                              marginTop={2}
                              type="text"
                              id="email"
                              name="email"
                              defaultValue={
                                !cartItem?.altShippingAddress
                                  ? billingAddress?.email
                                  : cartItem?.altShippingAddress?.email
                              }
                              placeholder="Enter email"
                              register={register}
                              errors={errors}
                              errorMsg="Please enter your email"
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  mr: 2,
                                }}
                              >
                                {' '}
                                <InputField
                                  marginTop={2}
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Enter recipient name"
                                  register={register}
                                  errors={errors}
                                  errorMsg="Please enter recipient name"
                                  defaultValue={
                                    !cartItem?.altShippingAddress
                                      ? billingAddress?.name
                                      : cartItem?.altShippingAddress?.name
                                  }
                                />
                              </Box>

                              <Box
                                sx={{
                                  flex: 1,
                                  ml: 2,
                                }}
                              >
                                {' '}
                                <InputField
                                  marginTop={2}
                                  type="text"
                                  id="surname"
                                  name="surname"
                                  placeholder="Enter recipient surname"
                                  register={register}
                                  errors={errors}
                                  errorMsg="Please enter recipient surname"
                                  defaultValue={
                                    !cartItem?.altShippingAddress
                                      ? billingAddress?.name
                                      : cartItem?.altShippingAddress?.name
                                  }
                                />
                              </Box>
                            </Box>

                            <InputField
                              marginTop={2}
                              type="text"
                              id="address"
                              name="address"
                              placeholder="Enter address"
                              register={register}
                              errors={errors}
                              errorMsg="Please enter your address"
                              defaultValue={
                                !cartItem?.altShippingAddress
                                  ? billingAddress?.address
                                  : cartItem?.altShippingAddress?.address
                              }
                            />

                            <InputField
                              marginTop={2}
                              type="text"
                              id="apartment"
                              name="apartment"
                              placeholder="Enter apartment, suite, etc. (optional)"
                              register={register}
                              errors={errors}
                              defaultValue={
                                !cartItem?.altShippingAddress
                                  ? billingAddress?.apartment
                                  : cartItem?.altShippingAddress?.apartment
                              }
                            />

                            <InputField
                              marginTop={2}
                              type="text"
                              id="suburb"
                              name="suburb"
                              placeholder="Enter suburb"
                              register={register}
                              errors={errors}
                              errorMsg="Please enter your suburb"
                              defaultValue={
                                !cartItem?.altShippingAddress
                                  ? billingAddress?.suburb
                                  : cartItem?.altShippingAddress?.suburb
                              }
                            />

                            <InputField
                              marginTop={2}
                              type="text"
                              id="city"
                              name="city"
                              placeholder="Enter city"
                              register={register}
                              errors={errors}
                              errorMsg="Please enter your city"
                              defaultValue={
                                !cartItem?.altShippingAddress
                                  ? billingAddress?.city
                                  : cartItem?.altShippingAddress?.city
                              }
                            />

                            <Box
                              sx={{
                                display: 'flex',
                              }}
                            >
                              <Box
                                sx={{
                                  flex: 4,
                                  mr: 1,
                                }}
                              >
                                <SelectField
                                  marginTop={2}
                                  id="country"
                                  defaultValue={
                                    !cartItem?.altShippingAddress
                                      ? billingAddress?.country
                                      : cartItem?.altShippingAddress?.country
                                  }
                                  register={register}
                                  errors={errors}
                                  errorMsg="Please select a country"
                                  label="Country/Region"
                                  itemList={[
                                    {
                                      name: 'South Africa',
                                    },
                                  ]}
                                />
                              </Box>
                              <Box
                                sx={{
                                  flex: 4,
                                  mr: 1,
                                  ml: 1,
                                }}
                              >
                                <SelectField
                                  marginTop={2}
                                  id="province"
                                  defaultValue={
                                    !cartItem?.altShippingAddress
                                      ? billingAddress?.province
                                      : cartItem?.altShippingAddress?.province
                                  }
                                  register={register}
                                  errors={errors}
                                  errorMsg="Please select a province"
                                  label="Province"
                                  itemList={[
                                    {
                                      name: 'Gauteng',
                                    },
                                    {
                                      name: 'Western Cape',
                                    },
                                    {
                                      name: 'Eastern Cape',
                                    },
                                    {
                                      name: 'Limpopo',
                                    },
                                    {
                                      name: 'North West',
                                    },
                                    {
                                      name: 'Kwazulu-Natal',
                                    },
                                    {
                                      name: 'Northern Cape',
                                    },
                                    {
                                      name: 'Mpumalanga',
                                    },
                                  ]}
                                />
                              </Box>
                              <Box
                                sx={{
                                  flex: 4,
                                  ml: 1,
                                }}
                              >
                                <InputField
                                  marginTop={2}
                                  type="text"
                                  id="postalCode"
                                  name="postalCode"
                                  defaultValue={
                                    !cartItem?.altShippingAddress
                                      ? billingAddress?.postalCode
                                      : cartItem?.altShippingAddress?.postalCode
                                  }
                                  placeholder="Enter postal code"
                                  register={register}
                                  errors={errors}
                                  errorMsg="Please enter your postal code"
                                />
                              </Box>
                            </Box>
                          </Stack>
                        )}
                      </form>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      onClick={() => {
                        formSubmit();
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        ) : (
          <>
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: '600',
                fontSize: '22px',
              }}
            >
              No Items in cart
            </Typography>
          </>
        )}
      </>
    </Container>
  );
}

export default CartDisplay;
