import { TextField } from '@mui/material';

function InputField({
  defaultValue,
  type,
  id,
  name,
  placeholder,
  errors,
  register,
  errorMsg,
  validationPattern,
  isRequired,
  marginTop,
  size,
  disabled,
  value,
  multiline,
}: any) {
  return (
    <>
      <TextField
        sx={{ mt: marginTop, width: '100%' }}
        type={type}
        size={size ? size : 'small'}
        defaultValue={defaultValue}
        id={id}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        {...register(`${id}`, {
          required: isRequired,
          pattern: validationPattern,
        })}
        error={errors[name] ? true : false}
        helperText={errors[name] ? errorMsg : ''}
        multiline={multiline}
      />
    </>
  );
}

export default InputField;
