import { useEffect, useState, useRef } from 'react';
import { apiFilterProducts } from '../../../api/apiMultiFilter';
import ProductGrid from './components/ProductGrid';
import FilterSelection from './components/FilterSelection';
import ApplicationSelection from './components/ApplicationSelection';
import ClearFilters from './components/ClearFilters';
import { Grid, Box, Typography, Container } from '@mui/material';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { convertToNumberArray } from '../../../utilities/ArrayConversion';

function FilterComponent({ itemGroup, title, initialApplications }: any) {
    const [availableApplications, setAvailableApplications] = useState([]);
    // const [applicationsList, setApplicationsList] =
    //     useState<string[]>(initialApplications);
    const [applicationsList, setApplicationsList] = useState<string[]>([]);

    const [availableColours, setAvailableColours] = useState([]);
    const [colourList, setColourList] = useState<number[]>([]);
    const [availableDesigns, setAvailableDesigns] = useState([]);
    const [designList, setDesignList] = useState<number[]>([]);
    const [availableTypes, setAvailableTypes] = useState([]);
    const [typeList, setTypeList] = useState<number[]>([]);
    const [productItemCount, setProductItemCount] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const totalPages = Math.ceil(productItemCount / itemsPerPage);
    const anchor = useRef(null);

    const getProductsMutation: any = useMutation(
        (filters: any) => {
            return apiFilterProducts(filters);
        },
        {
            onSuccess: (data) => {
                setAvailableApplications(data[data.length - 1]?.applications);
                setAvailableColours(data[data.length - 1]?.web_colours);
                setAvailableDesigns(data[data.length - 1]?.web_designs_style);
                setAvailableTypes(data[data.length - 1]?.webtypes);
                setProductItemCount(data[data.length - 1]?.total_items_count);
            },
        }
    );

    const [searchParams, setSearchParams]: any = useSearchParams();

    const addSearchParams = (elem: any, paramKey: string) => {
        const currentParams = new URLSearchParams(searchParams);
        currentParams.append(paramKey, elem);
        setSearchParams(currentParams);
    };

    const removeSearchParams = (item: any, paramKey: string) => {
        const currentParams = new URLSearchParams(searchParams);

        const convertedItem = item.toString();

        const selectedParams = currentParams.getAll(paramKey);

        const updatedParams = selectedParams.filter(
            (elem: string) => elem !== convertedItem
        );

        currentParams.delete(paramKey);

        updatedParams.forEach((param: string) => {
            currentParams.append(paramKey, param);
        });

        setSearchParams(currentParams);
    };

    //! Updates the state for all properties that can be filtered - colours,design etc
    const handleCheckboxChange =
        (
            elem: string,
            setList: React.Dispatch<React.SetStateAction<string[]>>,
            list: string[],
            paramKey: string
        ) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const isChecked = event.target.checked;

            if (isChecked) {
                setList([...list, elem]);
                addSearchParams(elem, paramKey);
            } else {
                setList(list.filter((item) => item !== elem));
                removeSearchParams(elem, paramKey);
            }
        };

    const getInitialData = () => {
        const currentParams: URLSearchParams = new URLSearchParams(
            searchParams
        );

        const applicationParams = currentParams.getAll('application');
        const colourParams = currentParams.getAll('colours');
        const designParams = currentParams.getAll('design_style');
        const typeParams = currentParams.getAll('types');

        const colours = convertToNumberArray(colourParams);
        const designs = convertToNumberArray(designParams);
        const types = convertToNumberArray(typeParams);

        const paramCount: number = Array.from(currentParams.keys()).length;

        if (paramCount > 0) {
            const filterByApplication = () => {
                setApplicationsList(applicationParams);
                setColourList(colours);
                setDesignList(designs);
                setTypeList(types);
                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },
                    off_setter: {
                        start: 0,
                        end: 9,
                    },
                    required_fields: {
                        item_group: itemGroup,
                        Applications: applicationParams,
                        WebTypes: typeParams,
                        WebColors: colourParams,
                        WebDesignStyles: designParams,
                    },
                });
            };
            filterByApplication();
        } else {
            const initialApplicationFilter = () => {
                // setApplicationsList(initialApplications);
                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },
                    off_setter: {
                        start: 0,
                        end: 9,
                    },
                    required_fields: {
                        item_group: itemGroup,
                        Applications: applicationsList,
                        WebTypes: [],
                        WebColors: [],
                        WebDesignStyles: [],
                    },
                });
            };

            initialApplicationFilter();
        }
    };

    const clearFilters = async () => {
        setApplicationsList([]);
        setColourList([]);
        setDesignList([]);
        setTypeList([]);
        filterByAllProperties();
        setCurrentPage((prevPage) => {
            prevPage = 1;

            return prevPage;
        });
    };

    const nextPage = async () => {
        if (currentPage === totalPages) {
            return;
        } else {
            setCurrentPage((prevPage) => {
                const nextPage = prevPage + 1;
                const startOffset = (nextPage - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },

                    required_fields: {
                        item_group: itemGroup,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                        WebTypes: typeList.length === 0 ? [] : typeList,
                        WebColors: colourList.length === 0 ? [] : colourList,
                        WebDesignStyles:
                            designList.length === 0 ? [] : designList,
                    },
                });

                return nextPage;
            });
        }
    };

    const previousPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            setCurrentPage((prevPage) => {
                const previousPage = prevPage - 1;
                const startOffset = (previousPage - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },

                    required_fields: {
                        item_group: itemGroup,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                        WebTypes: typeList.length === 0 ? [] : typeList,
                        WebColors: colourList.length === 0 ? [] : colourList,
                        WebDesignStyles:
                            designList.length === 0 ? [] : designList,
                    },
                });

                return previousPage;
            });
        }
    };

    const lastPage = () => {
        if (currentPage === totalPages) {
            return;
        } else {
            setCurrentPage((prev) => {
                prev = totalPages;
                const startOffset = (prev - 1) * itemsPerPage;
                const endOffset = startOffset + itemsPerPage;

                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },
                    required_fields: {
                        item_group: itemGroup,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                        WebTypes: typeList.length === 0 ? [] : typeList,
                        WebColors: colourList.length === 0 ? [] : colourList,
                        WebDesignStyles:
                            designList.length === 0 ? [] : designList,
                    },
                });

                return prev;
            });
        }
    };

    const firstPage = () => {
        if (currentPage === 1) {
            return;
        } else {
            setCurrentPage((prev) => {
                prev = 1;
                const startOffset = 0;
                const endOffset = 9;

                getProductsMutation.mutate({
                    filter: {
                        item_group: itemGroup,
                    },

                    off_setter: {
                        start: startOffset,
                        end: endOffset,
                    },
                    required_fields: {
                        item_group: itemGroup,
                        Applications:
                            applicationsList.length === 0
                                ? []
                                : applicationsList,
                        WebTypes: typeList.length === 0 ? [] : typeList,
                        WebColors: colourList.length === 0 ? [] : colourList,
                        WebDesignStyles:
                            designList.length === 0 ? [] : designList,
                    },
                });

                return prev;
            });
        }
    };
    // !In Progress
    // const initialApplicationFilter = () => {
    //     setApplicationsList(initialApplications);
    //     getProductsMutation.mutate({
    //         filter: {
    //             item_group: itemGroup,
    //         },
    //         off_setter: {
    //             start: 0,
    //             end: 9,
    //         },
    //         required_fields: {
    //             item_group: itemGroup,
    //             // Applications: [],
    //             // Applications:
    //             //     initialApplications.length === 0 ? [] : initialApplications,
    //             // Applications: applicationsList,
    //             Applications: initialApplications,
    //             WebTypes: [],
    //             WebColors: [],
    //             WebDesignStyles: [],
    //         },
    //     });
    // };

    // const filterByApplication = () => {
    //     // ! remove this
    //     // setApplicationsList(initialApplications);
    //     getProductsMutation.mutate({
    //         filter: {
    //             item_group: itemGroup,
    //         },
    //         off_setter: {
    //             start: 0,
    //             end: 9,
    //         },
    //         required_fields: {
    //             item_group: itemGroup,
    //             Applications:
    //                 // applicationsList.length === 0 ? [] : applicationsList,
    //                 initialApplications,
    //             WebTypes: [],
    //             WebColors: [],
    //             WebDesignStyles: [],
    //         },
    //     });
    // };

    const filterByAllProperties = () => {
        getProductsMutation.mutate({
            filter: {
                item_group: itemGroup,
            },
            off_setter: {
                start: 0,
                end: 9,
            },
            required_fields: {
                item_group: itemGroup,
                Applications:
                    applicationsList.length === 0 ? [] : applicationsList,
                WebTypes: typeList.length === 0 ? [] : typeList,
                WebColors: colourList.length === 0 ? [] : colourList,
                WebDesignStyles: designList.length === 0 ? [] : designList,
            },
        });
    };

    useEffect(() => {
        getInitialData();
    }, []);

    // !In Progress
    //useEffect(() => {
    //    filterByApplication();
    //}, []);

    return (
        <Container maxWidth='xl'>
            <Typography
                variant='h3'
                mb={1}
                mt={10}
                ml={2}
                fontWeight={400}
                fontSize={'40px'}
                textTransform={'capitalize'}
            >
                {` Discover - ${title}`}
            </Typography>
            <Typography fontWeight={400} fontSize='18px' mb={'-4rem'} ml={2}>
                Our inventory features thousands of designs. Use the selection
                bar below to find exactly what you’re looking for.
            </Typography>

            <Grid
                container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', lg: 'row' },
                    height: 'auto',
                    width: '100%',
                    padding: '1rem',
                    m: '4rem 0',
                }}
            >
                <Box
                    sx={{
                        flex: { xs: '12', lg: '4' },
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        mr: { xs: 0, lg: 5 },
                    }}
                >
                    <ClearFilters clearFilters={clearFilters} />
                    <ApplicationSelection
                        availableApplications={availableApplications}
                        handleCheckboxChange={handleCheckboxChange}
                        applicationsList={applicationsList}
                        setApplicationsList={setApplicationsList}
                    />

                    <FilterSelection
                        getProductsMutation={getProductsMutation}
                        availableColours={availableColours}
                        availableDesigns={availableDesigns}
                        availableTypes={availableTypes}
                        handleCheckboxChange={handleCheckboxChange}
                        filterByAllProperties={filterByAllProperties}
                        colourList={colourList}
                        designList={designList}
                        typesList={typeList}
                        setDesignList={setDesignList}
                        setColourList={setColourList}
                        setTypesList={setTypeList}
                        anchorRef={anchor}
                    />
                </Box>
                <Box
                    flex={{ xs: 12, lg: 8 }}
                    sx={{
                        height: '100%',
                        flexDirection: 'column',
                    }}
                >
                    {getProductsMutation?.data?.length === 1 &&
                    getProductsMutation?.data[0]?.total_items_count === 0 ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '80vh',
                                }}
                            >
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={'bold'}
                                >
                                    No items found
                                </Typography>
                                <Typography
                                    fontSize={'1.5rem'}
                                    fontWeight={'bold'}
                                >
                                    Please try a different selection{' '}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <>
                            {' '}
                            <ProductGrid
                                productItemCount={productItemCount}
                                getProductsMutation={getProductsMutation}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                lastPage={lastPage}
                                firstPage={firstPage}
                                anchorRef={anchor}
                            />
                        </>
                    )}
                </Box>
            </Grid>
        </Container>
    );
}

export default FilterComponent;
